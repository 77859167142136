

var app = null;

jQuery.noConflict();

(function($) {

	'use strict'; // Use strict mode inside class, not in the global scope

	function dimensions(settings) {

		var $this = this;

		var defaults = {};

		// Settings
		$this.settings = $.extend({}, defaults, settings || {});

		// Init
		$this.init();
	}

	dimensions.prototype = {

		// Animation
		animSpeed: {
			faster: 100,
			fast: 200,
			medium: 400,
			slow: 500,
			slower: 800 
		},

		/********************************************************
		* Constructor method
		*******************************************************/
		init: function() {

			var $this = this;

			// Global events
			$this.globalEvents();

			// Fancybox
			$this.fancybox();

			// Isotope
			$( window ).on( "load", function() {
				$this.isotope();

				// Breadcrumb
				$this.breadcrumb();
			});
			
			// Mobile menu
			$this.mobileMenuExpand();

			// Tablet menu
			$this.tabletMenuExpand();

			// Career
			$this.careers();

			// Animate
			$this.animate();
		},


		/********************************************************
		* Events called in every section
		*******************************************************/
		globalEvents: function() {

			var $this = this;

			/**
			* Open mobile menu
			*/
			$(document).on('click touchstart', '#menu-mobile-toggle', function(event) {
				event.preventDefault();

				$('body').toggleClass('mobile-menu-open');
				$('#header-nav').toggleClass('open');
			});


			/**
			* Fix main menu on scroll
			*/
			$(document).on("scroll",function(){
				if($(document).scrollTop()>100){
					$(".wrapper-header").addClass("smaller");
				} else{
					$(".wrapper-header").removeClass("smaller");
				}
			});


			/**
			* When over, put the item on front with z-index
			*/
			$('.filters').on('mouseenter touchstart', '.doors-windows .grid-item a', function(event) {
				event.preventDefault();
				
				var $this = $(this);

				// Remove all other Z-index
				$('.grid-item a').attr('style', '');
				// Add z-index on hover
				$this.attr('style', 'z-index:99;');
			});

			$('#nav-primary .nav.primary > li > ul.sub-menu').wrap('<div class="sub-menu-container"><div class="container"></div></div>');
		},


		/********************************************************
		* Fancybox
		*******************************************************/
		fancybox: function() {

			var $this = this;

			// Detect if there a default Wordpress Gallery.
			if($('.gallery').length) {

				// Add relation for Fancybox
				$('.gallery-item a').attr('rel', 'fancybox');
			}

			// Init Fancybox
			$(".fancybox, .gallery a, .achievements .grid a").fancybox({
				padding		: "0",
				fitToView	: true,
				width		: '80%',
				height		: '80%',
				maxWidth	: 1180,
				aspectRatio	: true,
				openEffect	: 'fade',
				closeEffect	: 'fade',
				openSpeed	: 400,
				closeSpeed	: 200,
				helpers: {
					overlay: {
						locked: false
					}
				}
			});
		},


		/********************************************************
		* Isotope - http://isotope.metafizzy.co/filtering.html
		*******************************************************/
		isotope: function() {

			var $this = this;
			
			// Create an array to get category class from all active item after a completion
			window.secondaryCategoryArray = [];

			// True or false if primary or secondary level filter is active
			window.primaryActive = false;
			window.secondaryActive = false;

			// True or false if all button is active in secondary level
			window.primaryActiveCategoryAll = true;

			// Store filter for each group
			var filters = {};


			// init Isotope
			var $grid = $('.grid').isotope({
				itemSelector: '.grid-item',
				layoutMode:   'fitRows',
				filter: 	  '*'
			});


			// When click on primary level filter except All button
			$('.filters').on( 'click touchstart', '#level-primary .button:not(.all)', function(event) {
				event.preventDefault();

				var $this = $(this);

				// Hide all secondary level item
				$('#level-secondary li').removeClass('visible').hide();

				// Reset the secondary filter
				$('#level-secondary .all').trigger('click');
				$('#level-secondary .is-checked').removeClass('is-checked');

				// If currently active
				if ($this.hasClass('is-checked')) {
					// Desactivate
					window.primaryActive = false;
					$(this).removeClass('is-checked');
					$('#level-primary .all').trigger('click');
				} else {
					// Activate
					window.primaryActive = true;
				}				
			});


			// When click on secondary level except All button
			$('.filters').on( 'click touchstart', '#level-secondary .button:not(.all)', function(event) {
				event.preventDefault();

				var $this = $(this);

				// If currently active
				if ($this.hasClass('is-checked')) {
					// Desactivate
					window.secondaryActive = false;
					$this.removeClass('is-checked').closest('.button-group').find('.all').trigger('click');
				} else {
					// Activate
					window.secondaryActive = true;
				}
			});


			// When click on any filter button
			$('.filters').on( 'click touchstart', '.button:not(.is-checked)', function(event) {
				event.preventDefault();

				var $this = $(this);

				$(".grid .grid-item.filtered").removeClass('filtered');

				// get group key
				var $buttonGroup = $this.parents('.button-group');
				var filterGroup = $buttonGroup.attr('data-filter-group');

				// set filter for group
				filters[ filterGroup ] = $this.attr('data-filter');

				// combine filters
				var filterValue = concatValues( filters );

				$grid.isotope({ filter: filterValue });

				console.log('clicked button : ' + $this.html());

				// Function to show all when click on active button
				// Isotope doesn't support by default to reset the filter when you click on active button
				if(!$this.hasClass('all')) {
					$this.closest('.button-group').find('.is-checked').removeClass('is-checked');
					$this.addClass('is-checked');
				}
			});


			// When filter complete, show ONLY the secondary level
			$grid.on('arrangeComplete', function( event, filteredItems ) {

				var secondaryCategoryArray = [];

				var iso = $grid.data('isotope');
				// add class to first 6 elements
				iso.filteredItems.forEach( function( item, i ) {
				  $( item.element ).addClass('filtered');
				});

				// Show secondary level ONLY if Primary level is active and NOT the All button
				if (window.primaryActive) {

					// Hide all secondary level items
					$('#level-secondary li').removeClass('visible').hide();

					var itemVisible = $(".grid .grid-item.filtered");

					// For each visible item in grid, get the related category and show the relative in secondary level
					itemVisible.each(function(i){
						var $this = $(this),
							activeItemCategory = $this.data('category');

						// If not empty
						if(activeItemCategory) {
							// Add category class to array
							secondaryCategoryArray.push(activeItemCategory);

							// Debug
							console.log(secondaryCategoryArray);
						}
					}).promise().done(function(){
						// Remove duplicata in array
						secondaryCategoryArray = $.unique(secondaryCategoryArray);

						// Debug
						console.log('Categories filtered : '+secondaryCategoryArray);
						
						// Loop through array and show only needed filter
						secondaryCategoryArray.forEach(function(item) {
							$('#level-secondary .'+item).show().addClass('visible');
						});

						// Show secondary filters after on complete
						$('#level-secondary').removeClass('invisible');
					}); 

					window.primaryActive = false;
				}

			});

				
			// Flatten object by concatting values
			function concatValues( obj ) {
				var value = '';
				for ( var prop in obj ) {
					value += obj[ prop ];
				}
				return value;
			}

		},


		/********************************************************
		* Breadcrumb
		*******************************************************/
		breadcrumb: function() {
			// http://www.jquerybyexample.net/2012/06/get-url-parameters-using-jquery.html

			if($('.page-template-template-category-doors-windows').length) {
				 
				if(window.location.hash) {
					var urlHashtag = window.location.hash.substr(1);
					var parts = urlHashtag.split("&");

					// Category
					if (parts[0]) {
						$('#'+parts[0]).trigger('click');
					}

					// Kind
					if (parts[1]) {
						setTimeout(
			            function() {
			                $('#level-secondary .all').trigger('click');
							$('#'+parts[1]).trigger('click');
			            },
			            1000);
					}
				}
			}
		},


		/********************************************************
		* Mobile - Main menu
		*******************************************************/
		mobileMenuExpand: function() {
			var $this = this;

			// Grab the html content of the main menu
			var menuHtml = $('.mobile #js-wrapper-nav').outerHTML();
			
			// Activate the function for mmenu
			$(".mobile #js-wrapper-nav").mmenu({
				configuration: {
					pageNodetype: "section"
				},
				
				slidingSubmenus: false
			});

			// Re-add the content for the menu on responsive
			$('.mobile #site-navigation').append(menuHtml);

			$(document).ready(function() {
				$('body').addClass('ready');
			});


			// MM Menu toggle
			$(document).on('click touchstart', "#navbar-toggle", function(event) {
				event.preventDefault();
				$("#js-wrapper-nav").trigger("open.mm");
			});
		},


		/********************************************************
		* Tablet - Main menu click on tablet
		*******************************************************/
		tabletMenuExpand: function() {
			var $this = this;

			var tabletSubMenuOpen = false;

			$(document).on('click touchend', ".tablet .menu-item-has-children a", function(event) {
				var $this = $(this);

				if (!$this.hasClass('selected')) {
					event.preventDefault();
					$('.selected').removeClass('selected');
					$this.addClass('selected');
				}
			});
		},


		/********************************************************
		* Carreers
		*******************************************************/
		careers: function() {
			var $this = this;

			/**
			* Change content of job description
			*/
			$(document).on('click touchstart', '#career-details .listing a', function(event) {
				event.preventDefault();

				var $this = $(this),
					jobID = $this.attr('data-id');

				if(!$this.hasClass('active')) {
					// Change selection on the left
					$('#career-details .listing a.active').toggleClass('active');
					$this.toggleClass('active')

					// Show content on the right
					$('#career-details .content-detail.active').toggleClass('active').slideToggle(400);
					$('#career-details .content-detail#'+jobID).toggleClass('active').slideToggle(400);
				}

				if($('html').hasClass('mobile')) {
					$('html, body').animate({
						scrollTop: $("#career-details .details").offset().top-20
					}, 400);
				}
			});

			/**
			* Change value in form when dropdown selec
			*/
			$(document).ready(function() {
				if($('.page-template-template-careers').length) {
					var defaultJobValue = $('#career-form .job-select option:first-child').val();

					$('#your-post').val(defaultJobValue);
				}
			});

			$(document).on('change', '#career-form .job-select select', function(event) {
				event.preventDefault();

				var jobValue = $(this).val();
				
				$('#your-post').val(jobValue);
			});

			/**
			* Change value of input file
			*/
			$(document).on('change', '#career-form #cv', function(event) {
				var filename = $('#cv').val();
				$('.fake-file').html(filename);
			});
		},


		/********************************************************
		* Animate css on scroll
		*******************************************************/
		animate: function() {
			var $this = this;

			var $animation_elements = $('.animated'),
				$window = $(window);

			function check_if_in_view() {
				var window_height = $window.height(),
					window_top_position = $window.scrollTop(),
					window_bottom_position = (window_top_position + window_height);

				$.each($animation_elements, function() {
					var $element = $(this),
						$animation = $(this).attr('data-animation'),
						element_height = $element.outerHeight(),
						element_top_position = $element.offset().top,
						element_bottom_position = (element_top_position + element_height);

					//check to see if this current container is within viewport
					if ((element_bottom_position >= window_top_position) && (element_top_position <= window_bottom_position)) {
						$element.addClass($animation);
					}
				});
			}

			$window.on('scroll resize', check_if_in_view);

			$(document).ready(function() {
				check_if_in_view();
			});
		}

	};


	/********************************************************
	*  Create instance
	*******************************************************/
	$.dimensions = function(settings) {

		return new dimensions(settings);
	};

	app = $.dimensions({
		baseURL: ''
	});


}(jQuery));